<template>
  <en-dialog :model-value="modelValue" width="80%" title="服务列表" @close="$emit('update:model-value', false)">
    <template #operation>
      <button-ajax link :method="operation.export.click">导出</button-ajax>
    </template>
    <form-query :model="form.data" :method="table.get">
      <en-form-item label="单据搜索">
        <en-input v-model="form.data.quickSearch"  placeholder="车牌号/客户名称/联系人手机"></en-input>
      </en-form-item>
   
      <en-form-item label="服务类型">
        <select-maintain
          v-model="form.data.categoryKey"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['BNSOPRTCTGY']) }"
          :props="{ label: 'message', value: 'code' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="服务阶段">
        <select-maintain
          v-model="form.data.recordType"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['BORCTP']) }"
          :props="{ label: 'message', value: 'code' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="跟进时间">
        <en-date-picker
          v-model:start="vehicle.data.recordDateStart"
          v-model:end="vehicle.data.recordDateEnd"
          type="daterange"
          class="w-full"
        ></en-date-picker>
      </en-form-item>
      <en-form-item label="跟进人员">
        <select-maintain
          v-model="form.data.recordPreparedById"
          :ajax="{
            action: 'GET /enocloud/common/user',
            params: (params, name) => (params.payload = { name, includingDestroyed: true, advisorFirst: true })
          }"
          :props="{ label: 'name', value: 'id' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="服务备注">
        <en-input v-model="form.data.comment" type="textarea"></en-input>
      </en-form-item>
      <en-form-item label="跟进内容">
        <en-input v-model="form.data.recordComment" type="textarea"></en-input>
      </en-form-item>
      <en-form-item label="选择门店" prop="branchIds">
        <select-maintain
          v-model="form.data.branchIds"
          :ajax="{
            action: 'GET /enocloud/common/branch',
            params: (params) => {
              params.payload = { pagingEnabled: false }
            }
          }"
          :props="{ label: 'shortName', value: 'id' }"
          clearable
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="最后进厂日期">
        <en-date-picker
          v-model:start="form.data.lastServiceDateStart"
          v-model:end="form.data.lastServiceDateEnd"
          type="daterange"
          class="w-full"
        ></en-date-picker>
      </en-form-item>
     
    </form-query>
    <table-dynamic
      :height="600"
      code="BNOPRDFLD"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
    ></table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
import { useStore } from '@enocloud/utils'
const store = useStore()
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {
        children: {
          export: {
            async click() {
              return this.ajax('GET /enocloud/business/opportunity/record/export', this.table.$ajaxParams, this.store.openDownload())
            }
          }
        }
      },
      form: {
        data: {
          quickSearch: '',
          categoryKey: '',
          type: '',
          serviceCategoryId: '',
          preparedDateStart: '',
          preparedDateEnd: '',
          lastServiceDateStart: '',
          lastServiceDateEnd: '',
          comment: '',
          businessOpportunityComment: '',
          branchIds: [],
        


        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/business/opportunity/record/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = {...this.form.data,branchIds: this.store.user.branch?.id}
            }
          },
          export: {
            action: 'GET /enocloud/business/opportunity/record/export',
            data: 'array',
            loading: true,
            pagination: true
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
    this.form.data.branchIds = this.store.user.branch?.id ? [this.store.user.branch?.id] : []
  }
})
</script>
